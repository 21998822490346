import request from '@/utils/request.js'

export function listPageAll(params) {
    return request({
        url:'/api/loaclstorage/listPageAll',
        method:'get',
        params
    });
}

export function add(data) {
    return request({
        url:'/api/loaclstorage/add',
        method:'post',
        data
    });
}

export function delSupplier(id) {
    return request({
      url: "/api/loaclstorage/" + id,
      method: "delete"
    })
}
export function updateInit(id) {
    return request({
        url:'/api/loaclstorage/detail/' + id,
        method:'get',
      
    });
}

export function update(data) {
    return request({
      url: "/api/loaclstorage/update",
      data,
      method: "put"
    })
  }